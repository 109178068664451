import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import heroSlideImageFirst from "../assets/images/hero-slide-1.png";
import aboutUsImage from "../assets/images/about-us.png";
import { Context } from "../App";
import "../styles/home.scss";
import Carousel from "../components/Carousel";
import Aos from "aos";
import "aos/dist/aos.css";
import ContactForm from "../components/ContactForm";

const Home = () => {
  const [count, setCount] = useState(0);
  const [projectsTypes, setProjectsTypes] = useState(["Svi projekti"]);
  const [selectedProjectType, setSelectedProjectType] =
    useState("Svi projekti");
  const { projects, setProjects, devices, setDevices, sendPageView } =
    React.useContext(Context);
  const [deviceIndex, setDeviceIndex] = useState(1);

  useEffect(() => {
    Aos.init({ duration: 1700 });
    window.scrollTo(0, 0);
    sendPageView("/", "/");
  }, []);

  useEffect(() => {
    const projectTypes = [...new Set(projects.map((project) => project.type))];
    setProjectsTypes((prevTypes) => [
      ...new Set([...prevTypes, ...projectTypes]),
    ]);
  }, [projects]);

  const heroSlide = {
    title: "Kontrola pristupa za stambene zgrade",
    subtitle: "Siguran i jednostavan ulazak",
    image: heroSlideImageFirst,
    description: (
      <>
        <p>
          Jednostavan ulazak stanarima u stambenu zgradu koristeći privjeske,
          kartice, šifre ili mobitel.
        </p>
      </>
    ),
    actions: (
      <>
        <Link className="btn" to="/kontaktiraj-nas">
          Saznajte više
        </Link>
      </>
    ),
  };

  const benefits = [
    {
      title: "JEDNOSTAVNOST",
      description:
        "Jednostavan ulazak stanara (posebno kada se nose stvari u rukama, kad osoba ima više ključeva, kada je oštećen ili pokvaren cilindar, kada osoba ima slabiji vid, …).",
    },
    {
      title: "SIGURNOST",
      description:
        "Kod gubitka određenog elektronskog ključa (kartice ili privjeska) iz sustava se isključi samo izgubljeni ključ, praktično rješenje za djecu.",
    },
    {
      title: "UŠTEDA ",
      description:
        "Manje trošenje/habanje  klasičnih cilindara i ključeva, manje popravaka i izmjena.",
    },
  ];

  return (
    <main className="page home">
      <section className="hero">
        <div className="slide active" key={heroSlide.title}>
          <div className="slide__image">
            <img src={heroSlide.image} alt="" />
          </div>

          <div className="slide__content">
            <h2>{heroSlide.subtitle}</h2>
            <h1>{heroSlide.title}</h1>
            <div className="slide__description">{heroSlide.description}</div>
          </div>
        </div>

        <div className="benefits">
          {benefits.map((benefit, index) => (
            <div
              className="benefit"
              key={benefit.title}
              data-aos-once="false"
              data-aos="fade-up"
              data-aos-duration="350"
              data-aos-delay={index * 150}
            >
              <h3>{benefit.title}</h3>
              <p>{benefit.description}</p>
            </div>
          ))}
        </div>
      </section>

      <section className="about-us">
        <div className="content">
          {" "}
          <h1>
            <span>O</span> nama
          </h1>
          <p>
            Pružamo usluge ugradnje sustava kontrole pristupa za stambene zgrade
            i poslovne objekte. Više od 10 godina iskustva. Pri nabavci
            koristimo uređaje i opremu renomiranih svjetskih proizvođača{" "}
            {"( Sebury )"}. Proteklih godina smo odradili više od 100 ulaza te
            skupili samo zadovoljne klijente.
          </p>{" "}
          <div className="info">
            <a href="tel:+385917344974">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
              <span>+385 91 734 4974</span>
            </a>{" "}
            <a href="mailto:info@msk.hr">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>

              <span>info@msk.hr</span>
            </a>
          </div>
          <div className="references">
            <div className="reference">
              <h3>100+</h3>
              <h4>Odrađenih ulaza</h4>
            </div>{" "}
            <div className="reference">
              <h3>10+</h3>
              <h4>Godina iskustva</h4>
            </div>
          </div>
        </div>

        <div className="image">
          <img src={aboutUsImage} alt="" />
        </div>
      </section>

      <section className="projects">
        <h1 data-aos-once="false" data-aos="fade-up" data-aos-duration="350">
          Izdvojeni <span>projekti</span>
        </h1>

        <Carousel />
      </section>

      <section className="devices">
        <h1
          data-aos-once="false"
          data-aos="fade-up"
          data-aos-duration="350"
          data-aos-delay="150"
        >
          <span>Uređaji</span> koje koristimo
        </h1>

        <div className="devices__container">
          {devices.map((device, index) => (
            <div
              className="device"
              key={device.index + device.title}
              data-aos-once="false"
              data-aos={index % 2 === 0 ? "fade-up" : "fade-up"}
              data-aos-duration="350"
            >
              <div className="content">
                <div className="top">
                  <h3>{device.title}</h3>
                  <p>{device.shortDescription}</p>
                </div>
                <div className="bottom">
                  <Link className="outside__btn" to={`/uredaji/${device.slug}`}>
                    Saznajte više
                  </Link>
                </div>
              </div>
              <div className="image">
                <img src={device.image} alt="" />
              </div>
            </div>
          ))}{" "}
        </div>
      </section>

      <ContactForm />
    </main>
  );
};

export default Home;
