import React from "react";

const ContactForm = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    const data = {
      name: formData.get("name"),
      surname: formData.get("surname"),
      email: formData.get("email"),
      phone: formData.get("telephone"),
      message: formData.get("message"),
    };

    try {
      const response = await fetch(
        "https://mail-server-gold.vercel.app/send-email/kontrola-pristupa",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      await response.json();

      alert("Poruka je uspješno poslana!");
    } catch (error) {
      console.error("Error:", error);
      alert("Došlo je do greške prilikom slanja poruke!");
    }
  };

  return (
    <section className="contact">
      <div className="info">
        <h1>
          <span>KONTAKT</span> INFORMACIJE
        </h1>

        <p>
          Pogledajte kako sve možete stupit u kontakt s nama. Ukoliko imate bilo
          kakvih pitanja, slobodno nas kontaktirajte.
        </p>

        <ul className="contact__info">
          <li>
            Telefon: <span>+385 91 734 4974</span>
          </li>
          <li>
            E-mail:
            <span>info@msk.hr</span>
          </li>
        </ul>
        <p>
          <span>Adresa:</span> Ulica Ivane Brkanovića 3, 10360 Sesvete
        </p>

        <p>
          Pogledajte kako sve možete stupit u kontakt s nama. Ukoliko imate bilo
          kakvih pitanja, slobodno nas kontaktirajte.
        </p>
      </div>
      <form className="form" onSubmit={handleSubmit}>
        <h1>
          Kontaktirajte nas ispunjavanjem <span>obrasca.</span>
        </h1>

        <p>
          Potrudit ćemo se odgovoriti na Vaš upit u najkraćem mogućem roku.
        </p>

        <div className="row">
          <input type="text" placeholder="Ime*" id="name" name="name" />
          <input
            type="text"
            placeholder="Prezime *"
            id="surname"
            name="surname"
            required
          />
        </div>
        <div className="row">
          <input
            type="text"
            placeholder="Broj telefona *"
            id="telephone"
            name="telephone"
            required
          />
        </div>

        <input
          type="text"
          placeholder="E-mail *"
          id="email"
          name="email"
          required
        />

        <textarea
          placeholder="Poruka *"
          id="message"
          name="message"
          required
        ></textarea>

        <div className="checkbox">
          <input type="checkbox" id="terms" name="terms" required />
          <label htmlFor="terms">
            Slažem se s time da moji podaci budu pohranjeni i korišteni u svrhu
            ove poruke.
          </label>
        </div>

        <button className="btn">Pošalji</button>
      </form>{" "}
    </section>
  );
};

export default ContactForm;
