import React, { useContext, useEffect } from "react";
import "../styles/Cookies.scss";
import { Context } from "../App";

const Cookies = () => {
  const { cookiesAccepted, setCookiesAccepted } = useContext(Context);

  const handleCookiesAccept = () => {
    setCookiesAccepted(true);

    localStorage.setItem("cookiesAccepted", true);
  };

  useEffect(() => {
    if (localStorage.getItem("cookiesAccepted") === "true") {
      setCookiesAccepted(true);
    }
  }, []);

  return (
    <>
      <section className={`cookies ${cookiesAccepted ? "cookies-hidden" : ""}`}>
        <p className="cookies-description">
          Koristimo kolačiće kako bismo vam pružili najbolje iskustvo na našoj
          web stranici. Ova stranica koristi kolačiće trećih strana. Klikom na
          gumb "Prihvaćam" pristajete na korištenje kolačića.
        </p>
        <div className="cookies-buttons">
          <button
            className="cookies-button cookies-accept"
            onClick={handleCookiesAccept}
          >
            <span>Prihvaćam</span>🍪
          </button>
          <button className="cookies-button cookies-settings">
            <span>Odustani</span>
          </button>
        </div>
      </section>
    </>
  );
};

export default Cookies;
