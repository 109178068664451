import React, { useEffect, useState, useRef } from "react";
import contactImage from "../assets/images/contact.jpg";
import "../styles/contact.scss";
import Aos from "aos";
import "aos/dist/aos.css";
import { Context } from "../App";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  const { sendPageView } = React.useContext(Context);

  useEffect(() => {
    Aos.init({ duration: 1700 });
    window.scrollTo(0, 0);
    sendPageView("Kontakt", "/kontakt");
  }, []);
  return (
    <main className="contact page">
      <section className="page__hero">
        <div className="image">
          <img src={contactImage} alt="About us" />
        </div>

        <h1>Kontaktirajte nas</h1>
      </section>

      <ContactForm />
    </main>
  );
};

export default ContactUs;
