import React, { useEffect, useState } from "react";
import contactImage from "../assets/images/hero-slide-1.png";
import { Context } from "../App";
import Carousel from "../components/Carousel";
import { Link } from "react-router-dom";
import "../styles/projects.scss";
import Aos from "aos";
import "aos/dist/aos.css";

const Projects = () => {
  const { projects, setProjects, devices, setDevices, sendPageView } =
    React.useContext(Context);
  useEffect(() => {
    Aos.init({ duration: 1700 });
    window.scrollTo(0, 0);
    sendPageView("Projekti", "/projekti");
  }, []);
  return (
    <div className="page projects">
      <div className="page__hero">
        <div className="image">
          <img src={contactImage} alt="About us" />
        </div>

        <h1>Naši projekti</h1>
      </div>

      <div className="projects__container">
        {projects.map((project) => (
          <Link
            className="project"
            key={project.slug}
            data-aos="fade-up"
            data-aos-duration="500"
            to={`/projekti/${project.slug}`}
          >
            <div className="image">
              <img src={project.image} alt={project.title} />{" "}
            </div>
            <div className="content">
              {" "}
              <p className="project__type">{project.type}</p>
              <h3>{project.title}</h3>
              <p>{project.shortDescription}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Projects;
