import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import "../styles/header.scss";
import facebook_logo from "../assets/images/socials/facebook_logo.png";
import instagram_logo from "../assets/images/socials/instagram_logo.png";
import facebook__logo__black from "../assets/images/socials/facebook_logo__black.png";
import instagram__logo__black from "../assets/images/socials/instagram_logo__black.png";
import { useLocation } from "react-router-dom";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setShowMobileMenu(false); // Set showMobileMenu to false when the URL changes
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const nav = document.querySelector(".navbar");
      if (nav) {
        if (window.scrollY > 100) {
          nav.classList.add("scrolled");
        } else {
          nav.classList.remove("scrolled");
        }
      }
    });
  }, []);

  useEffect(() => {
    const nav = document.querySelector(".navbar");
    const urlParts = location.pathname.split("/");

    if (urlParts.length > 2) {
      nav.classList.add("black__background");
    } else {
      nav.classList.remove("black__background");
    }
  }, [location.pathname]);

  return (
    <>
      <nav className="navbar">
        <div className="navbar__left">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" />
          </Link>

          <div className="navbar__center">
            <NavLink to="/" exact>
              Početna
            </NavLink>
            <NavLink to="/projekti"> Projekti</NavLink>
            <NavLink to="/uredaji"> Uređaji</NavLink>
          </div>
        </div>

        <div className="navbar__right">
          <div className="info">
            <a href="tel:+385917344974">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                />
              </svg>
              <span>+385 91 734 4974</span>
            </a>{" "}
            <a href="mailto:info@msk.hr">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>

              <span>info@msk.hr</span>
            </a>
          </div>
          <div className="socials">
            <a
              className="social"
              href="https://www.facebook.com/profile.php?id=61551984313508"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook_logo} alt="facebook_logo" />
            </a>
            <a
              className="social"
              href="https://www.instagram.com/kontrola_pristupa_msk"
              target="_blank"
              rel="noreferrer"
            >
              <img src={instagram_logo} alt="instagram_logo" />
            </a>
          </div>
          <NavLink className="btn" to="/kontakt">
            Kontakt{" "}
          </NavLink>{" "}
          <button
            className="menu__btn"
            onClick={() => setShowMobileMenu(!showMobileMenu)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={0.7}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
      </nav>

      <nav className={`mobile__menu ${showMobileMenu ? "show" : ""}`}>
        <div className="top">
          <Link className="logo" to="/">
            <img src={logo} alt="logo" />
          </Link>{" "}
        </div>
        <div className="info">
          <a href="tel:+385917344974">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
              />
            </svg>
            <span>+385 91 734 4974</span>
          </a>{" "}
          <a href="mailto:info@msk.hr">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>

            <span>info@msk.hr</span>
          </a>
        </div>

        <div className="links">
          <NavLink to="/" exact>
            Početna
          </NavLink>{" "}
          <NavLink to="/projekti">Projekti</NavLink>
          <NavLink to="/uredaji">Uređaji</NavLink>
          <NavLink to="/kontakt">Kontakt</NavLink>
        </div>

        <div className="bottom">
          <div className="socials">
            <a
              className="social"
              href="https://www.facebook.com/profile.php?id=61551984313508"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={facebook__logo__black} alt="facebook_logo" />
            </a>{" "}
            <a
              className="social"
              href="https://www.instagram.com/kontrola_pristupa_msk"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img src={instagram__logo__black} alt="instagram_logo" />
            </a>
          </div>
          <div className="rights">
            <p>© 2024 MSK. Sva prava pridržana.</p>
          </div>
        </div>

        <button
          className={showMobileMenu ? "close__btn show" : "close__btn"}
          onClick={() => setShowMobileMenu(false)}
        ></button>
      </nav>
    </>
  );
};

export default Header;
