import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Devices from "./pages/Devices";
import ContactUs from "./pages/ContactUs";
import Projects from "./pages/Projects";
import Project from "./pages/Project";
import projectImageFirst from "./assets/images/project-1.jpg";
import projectImageSecond from "./assets/images/project-2.jpg";
import projectImageThird from "./assets/images/project-3.jpg";
import BEC03 from "./assets/images/devices/BEC-03.png";
import BC2000 from "./assets/images/devices/BC-2000.png";
import W3C from "./assets/images/devices/W3-C.png";
import K2 from "./assets/images/devices/K2.png";
import sTouch2 from "./assets/images/devices/sTouch2.png";
import BEC02 from "./assets/images/devices/BEC-02.png";
import BEC04 from "./assets/images/devices/BEC-04.png";
import Device from "./pages/Device";
import Header from "./components/Header";
import Cookies from "./components/Cookies";
import Footer from "./components/Footer";
import ReactGA from "react-ga4";

export const Context = React.createContext();

function App() {
  const [projects, setProjects] = useState([
    {
      slug: "ugradnja-kontrole-pristupa-u-stambenim-zgradama",
      title: "Ugradnja kontrole pristupa u stambenim zgradama",
      shortDescription:
        " Od 2014. godine ugradili smo sustav kontrole pristupa u više od 100 ulaza stanbenih zgrada. ",
      description:
        " Od 2014. godine ugradili smo sustav kontrole pristupa u više od 100 ulaza stanbenih zgrada. ",
      image: projectImageFirst,
      year: "2014 - 2024",
      type: "Stambene zgrade",
      content:
        "<div class='slide'> <div class='image'><img src='https://res.cloudinary.com/dvg2o5azu/image/upload/v1710626782/BC-2000_c50yq5.png' /></div><div class='content'><h1>Specifikacije uređaja</h1><p>Uređaj može imati do 1000 korisnika što je u većini slučajeva sasvim dovoljno. <p/> <p>Uređaj ima opciju ulaska putem PIN-a te prislanjanjem kartice ili ključa.<p/> <p>Uređaj je otporan na vremenske uvjete te je pogodan za postavljanje na otvorenom.<p/> <p>Uređaj je otporan na vremenske uvjete te je pogodan za postavljanje na otvorenom.<p/> <p>Postavljanjem našeg uređaja životni vijek ulaznih vrata će se poduplati.<p/></div></div>",
    },
    {
      slug: "omogucen-ulaz-u-objekt-pomocu-mobitela",
      title: "Omogućen ulaz u objekt pomoću mobitela",
      shortDescription:
        "Ugradnja kontrole pristupa u zasebni dio objekta sa čitačem koji osim privjesaka i šifre koristi i WIFI modul, otisak prsta te daljinsko otvaranje pomoću mobitela.",
      description:
        "Ugradnja kontrole pristupa u zasebni dio objekta sa čitačem koji osim privjesaka i šifre koristi i WIFI modul, otisak prsta te daljinsko otvaranje pomoću mobitela.",
      image: projectImageSecond,
      year: "2024",
      content:
        "<div class='slide'> <div class='image'><img src='https://res.cloudinary.com/dvg2o5azu/image/upload/v1710687385/WhatsApp_Image_2024-03-16_at_18.28.52_16d7aec0_jobsmo.jpg' /></div><div class='content'><h1>OTVARANJE VRATA POMOĆU MOBILNOG UREĐAJA</h1><p>1.Adopt the non-electromagnetic shielding metal access control patent technology, patent NO.:CN210610015U<p/> <p>2.Innovative All-metal Design,no antenna window, Large metal keypad, European and American Style<p/> <p>3.125KHz and 13.56MHz Dual-band antenna, support  EM/HID/MF/NFC cards<p/> <p>4.Standalone mode, anti-pass back mode: wiegand input, external card reader can be connected<p/> <p>5.Reader mode: wiegand ouput, LED and Buzzer control input<p/> <p>6.Fully potted process, IP68<p/></div></div><div class='slide reverse'> <div class='image'><img src='https://res.cloudinary.com/dvg2o5azu/image/upload/v1710693270/WhatsApp_Image_2024-03-16_at_18.28.52_c0d98a30_g2ybvy.jpg' /></div><div class='content'><h1>Naslov</h1><p>1.Adopt the non-electromagnetic shielding metal access control patent technology, patent NO.:CN210610015U<p/> <p>2.Innovative All-metal Design,no antenna window, Large metal keypad, European and American Style<p/> <p>3.125KHz and 13.56MHz Dual-band antenna, support  EM/HID/MF/NFC cards<p/> <p>4.Standalone mode, anti-pass back mode: wiegand input, external card reader can be connected<p/> <p>5.Reader mode: wiegand ouput, LED and Buzzer control input<p/> <p>6.Fully potted process, IP68<p/></div></div>",
      type: "Estink",
    },
    {
      slug: "kontrola-pristupa-u-djecjem-vrticu-en-ten-tini",
      title: "Kontrola pristupa u Dječjem vrtiću EN TEN TINI",
      shortDescription:
        "Ugradnja kontrole pristupa u objektima Dječjeg vrtića EN TEN TINI. ( područni objekt - 2015, centralni objekt - 2017)",
      description:
        "Ugradnja kontrole pristupa u objektima Dječjeg vrtića EN TEN TINI. ( područni objekt - 2015, centralni objekt - 2017)",
      image: projectImageThird,
      year: "2017",
      content: "",
      type: "Dječji vrtić",
    },
  ]);

  const [devices, setDevices] = useState([
    {
      title: "BC-2000",
      slug: "bc-2000",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Metal Waterproof Keypad",
      image: BC2000,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },
      content: `
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407818/02_wddwdj.jpg' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407818/04_k1c8i7.jpg' /></div>
        </div><div class='slide'>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407818/01_tgv2no.jpg' /></div>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407818/03_un9i3i.jpg' /></div>
      </div>`,
    },
    {
      title: "K2",
      slug: "k2",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Metal Waterproof Keypad",
      image: K2,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },
      video:
        "https://res.cloudinary.com/dmqufec5z/video/upload/v1698407969/video_ibytzr.mp4",
      content: `
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407968/02_ec20rj.jpg' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407968/01_uygjym.jpg' /></div>
        </div><div class='slide'>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407968/04_trropy.jpg' /></div>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407968/05_rdrkpa.jpg' /></div>
      </div><div class='slide'>
      <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407969/08_lewslp.jpg' /></div>
      <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407969/07_m0d9nk.jpg' /></div>
    </div>`,
    },

    {
      title: "W3-C",
      slug: "w3-c",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Metal Waterproof Keypad",
      image: W3C,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },
      content: `
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698411825/2_p5vbfi.jpg' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698411825/3_ozbqkb.jpg' /></div>
        </div><div class='slide'>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698407818/01_tgv2no.jpg' /></div>
        <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698411825/1_njo0my.jpg' /></div>
      </div>`,
    },
    {
      slug: "s-touch2",
      title: "sTouch2",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Metal Waterproof Keypad",
      image: sTouch2,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },
      video:
        "https://res.cloudinary.com/dmqufec5z/video/upload/v1698412573/sTouch2_product_promotion_video_45s__x264_wysjfu.mp4",
      content: `
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698412574/sTouch2-1_wqasii.jpg' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698412574/sTouch2-1_wqasii.jpg' /></div>
        </div>
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698412571/sTouch2_electroplate-1_ewzcld.jpg' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698412570/8_x6gpbq.jpg' /></div>
        </div>
        `,
    },
    {
      title: "BEC-04",
      slug: "BEC-04",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Privjesci za ulazak",
      image: BEC04,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },

      content: `
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698413279/BEC-04_crveni_z5iuaf.png' /></div>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698413278/BEC-04_crni_uz4nbm.jpg' /></div>
        </div>
        <div class='slide'>
          <div class='image'><img src='https://res.cloudinary.com/dmqufec5z/image/upload/v1698413281/BEC-04_plavi_od8njq.png' /></div>
          <div class='image'></div>
        </div>
        `,
    },
    {
      title: "BEC-03",
      slug: "BEC-03",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Privjesci za ulazak",
      image: BEC03,
      specifications: {
        material: "Zinc Alloy",
        inputVoltage: "DC12~24V",
        idleCurrent: "≤50mA",
        cardReadingType: "125KHz: EM/HID 13.56MHz: MF/CPU/NFC (ISO14443A)",
        readingCardDistance: "125KHz: ≤5cm  13.56MHz: ≤2cm",
        outputFormatCard:
          "Wiegand 26-37 bit (HID card output Wiegand 26-37 automatically.) Keypad: 4 bit, 8 bit, virtual card number",
        workingTemperature: "-20~60°C",
        workingHumidity: "0~95% (no condensation)",
        productDimension: "170 * 83 * 26mm",
      },

      content: `
        
        
        `,
    },
    {
      title: "BEC-02",
      slug: "BEC-02",
      description: `
        <ul>
          <li>All-metal Password standalone access control/reader</li>
          <li>Material: Zinc Alloy</li>
          <li>Input Voltage: DC12~24V</li>
          <li>Product Dimension: 170 * 83 * 26mm</li>
          <li>Idle Current: ≤50mA</li>
        </ul>`,
      shortDescription: "Kartica za ulazak",
      image: BEC02,
      specifications: {},
      content: `
       
        `,
    },
  ]);

  const [cookiesAccepted, setCookiesAccepted] = useState(false);

  useEffect(() => {
    let c = localStorage.getItem("cookiesAccepted");

    if (c !== "true") {
      localStorage.setItem("cookiesAccepted", false);
    } else {
      ReactGA.initialize("G-S6YM2JB0BT");
      console.log("GA initialized");
    }
  }, [cookiesAccepted]);

  const sendPageView = (title, page) => {
    let c = localStorage.getItem("cookiesAccepted");

    if (c !== "true" || c === null) {
      console.log("Cookies not accepted");
      return;
    }
    console.log("Cookies accepted", c);
    console.log("Sending page view");

    ReactGA.send({
      hitType: "pageview",
      page: page,
      title: page,
    });
  };

  return (
    <>
      <Context.Provider
        value={{
          projects,
          setProjects,
          devices,
          setDevices,
          sendPageView,
          cookiesAccepted,
          setCookiesAccepted,
        }}
      >
        <BrowserRouter>
          <Header />
          <Cookies />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/uredaji" element={<Devices />} />
            <Route path="/kontakt" element={<ContactUs />} />
            <Route path="/projekti" element={<Projects />} />
            <Route path="/projekti/:slug" element={<Project />} />
            <Route path="/uredaji/:slug" element={<Device />} />
          </Routes>{" "}
          <Footer />
        </BrowserRouter>
      </Context.Provider>
    </>
  );
}

export default App;
