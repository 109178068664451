import React, { useContext } from "react";
import "../styles/footer.scss";
import logoWhite from "../assets/images/logo.svg";
import { Link } from "react-router-dom";
import { Context } from "../App";
import facebook_logo from "../assets/images/socials/facebook_logo.png";
import instagram_logo from "../assets/images/socials/instagram_logo.png";

const Footer = () => {
  const { projects } = useContext(Context);

  return (
    <>
      <footer className={"footer"}>
        <section className="box-container">
          <div className="box first">
            <img src={logoWhite} alt="logo" className="logo" />
            <p>
              Bavimo se montažom i održavanjem sustava kontrole pristupa u
              zgradama.
            </p>
            <div className="socials">
              <img
                src={facebook_logo}
                alt="facebook_logo"
                href="https://www.facebook.com/profile.php?id=61551984313508"
                target="_blank"
                rel="noreferrer"
              />
              <img
                src={instagram_logo}
                alt="instagram_logo"
                href="https://www.instagram.com/kontrola_pristupa_msk"
                target="_blank"
                rel="noreferrer"
              />
            </div>
          </div>
          <div className="box">
            <h3>Menu </h3>

            <ul>
              <li>
                <Link to="/"> Početna </Link>
              </li>
              <li>
                <Link to="/"> Uređaji</Link>
              </li>
              <li>
                <Link to="/"> Projekti</Link>
              </li>
              <li>
                <Link to="/"> O nama</Link>
              </li>
              <li>
                <Link to="/"> Kućanski aparati</Link>
              </li>
            </ul>
          </div>

          <div className="box">
            <h3> Projekti</h3>

            <ul>
              <li>
                <Link to="/projekti/ugradnja-kontrole-pristupa-u-stambenim-zgradama">
                  {" "}
                  Ugradnja kontrole pristupa u stambenim zgradama
                </Link>
              </li>
              <li>
                <Link to="/projekti/omogucen-ulaz-u-objekt-pomocu-mobitela">
                  {" "}
                  Omogućen ulaz u objekt pomoću mobitela
                </Link>
              </li>
              <li>
                <Link to="/projekti/kontrola-pristupa-u-djecjem-vrticu-en-ten-tini">
                  {" "}
                  Kontrola pristupa u Dječjem vrtiću EN TEN TINI
                </Link>
              </li>

              <li>
                <Link to="/projekti"> Pogledajte sve projekte</Link>
              </li>
            </ul>
          </div>

          <div className="box">
            <h3> Kontakt</h3>

            <ul>
              <li>
                Broj telefona: <a href="tel:+385917344974">+385 91 7344 974</a>{" "}
              </li>
              <li>
                E-mail: <a href="mailto:info@msk.hr">info@msk.hr</a>
              </li>
              <li>
                Klikom na link ispod možete ispuniti formu kako bi nas
                kontaktirali.
              </li>
              <Link className="btn" to="/kontakt">
                {" "}
                Kontaktirajte nas
              </Link>
            </ul>
          </div>
        </section>

        <section className="rights">
          <hr />
          <div className="bottom-container">
            <div className="links">
              <Link to="/">Pravila o privatnosti</Link>
              <Link to="/">Politika kolačića</Link>
              <Link to="/">O nama</Link>
            </div>
            <p>© 2023. Sva prava pridržana. </p>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
